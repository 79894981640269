<template>
  <span class="deposit-button" v-if="hasPerm('payments.add_deposit')">
    <a href @click.prevent="showModal" class="btn btn-primary" :class="{ disabled: isDepositDisabled(), }">
      <i class="fas fa-money-bill-alt"></i>
      {{ label }}
    </a>
    <b-modal dialog-class="modal-xl"
      :id="getId()"
      cancel-title="Annuler"
      :ok-disabled="isDepositDisabled()"
      @ok.prevent="onDeposit"
      ok-variant="primary"
      ok-title="Ok"
    >
      <template v-slot:modal-title>
        <b><i class="fas fa-money-bill-alt"></i> Dépôt</b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <b-row>
        <b-col cols="4">
          <b-row class="">
            <b-col>
              <b-form-group
                id="deposit-total-group"
                label="Montant total du paiement"
                label-for="deposit-total"
                description="Somme des paiements sélectionnés"
              >
                <b-form-input id="deposit-total" :value="depositTotal | currency" disabled></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="">
            <b-col>
              <b-form-group
                id="deposit-count-group"
                label="Nombre de paiements"
                label-for="deposit-count"
                description="Nombre de paiements sélectionnés"
              >
                <b-form-input id="deposit-count" :value="depositCount" disabled></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                id="deposit-date-group"
                label="Date de dépôt"
                label-for="deposit-date"
              >
                <b-form-input type="date" id="deposit-date" v-model="depositDate">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                id="number-group"
                label="Numéro"
                label-for="number"
              >
                <b-form-input id="number" v-model="depositNumber"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                id="comments-group"
                label="Commentaires"
                label-for="comments"
              >
                <b-form-input id="comments" v-model="comments"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="8">
          <table class="table table-striped table-small">
            <tr class="payment-line" v-for="payment of payments" :key="payment.id">
              <td>
                <span v-if="payment.emitter">{{ payment.emitter }} pour </span>
                {{ payment.entity.name }}
              </td>
              <td>{{ payment.paymentDate | dateToString }}</td>
              <td>{{ payment.bankNumber }}</td>
              <td>{{ payment.bankName }}</td>
              <td class="text-right">{{ payment.amount | currency }}</td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import moment from 'moment'
import { getRandomId } from '@/utils/random'
import { BackendApi } from '@/utils/http'
import { BackendMixin } from '@/mixins/backend'
import { mapActions } from 'vuex'

export default {
  name: 'deposit-button',
  mixins: [BackendMixin],
  props: {
    payments: Array,
    label: {
      type: String,
      default: 'Dépôt',
    },
  },
  data() {
    return {
      randomId: getRandomId(),
      depositDate: null,
      depositNumber: '',
      comments: '',
      errorText: '',
    }
  },
  computed: {
    depositTotal() {
      return this.payments.reduce(
        (accumulator, payment) => accumulator + payment.amount,
        0
      )
    },
    depositCount() {
      return this.payments.length
    },
  },
  watch: {
    payments: function(newValue, oldValue) {},
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    showModal() {
      this.$bvModal.show(this.getId())
    },
    getId() {
      return 'bv-modal-make-deposit' + this.randomId
    },
    isDepositDisabled() {
      return this.payments.length === 0
    },
    async onDeposit() {
      this.errorText = ''
      try {
        const url = '/api/deposits/'
        const backendApi = new BackendApi('post', url)
        let data = {
          payments: this.payments.map(elt => elt.id),
          comments: this.comments,
          number: this.depositNumber,
          deposit_on: '' + moment(this.depositDate).format('YYYY-MM-DD'),
        }
        await backendApi.callApi(data)
        this.$bvModal.hide(this.getId())
        this.$emit('deposit')
      } catch (err) {
        this.errorText = this.getErrorText(err)
        this.addError(this.getErrorText(err))
      }
    },
  },
  mounted() {
    this.depositDate = moment().format('YYYY-MM-DD')
  },
}
</script>
<style lang="less">
  .table-small tr td {
    padding: 2px;
    border-bottom: solid 1px #f0f0f0;
    margin: 2px;
    font-size: 10px;
  }
  .error-text {
    padding: 20px;
    color: #cc3700;
    background: #e0e0e0;
  }
</style>
